import FacebookIcon from '../assets/images/facebook_icon.png';
import LinkedinIcon from '../assets/images/linkedin_icon.png';
import WhatsappIcon from '../assets/images/whatsapp_icon.png';

export const AboutMe = () => {
  return (
    <div className="w-full h-auto relative font-roboto pb-[150px]">
        
        <div className="flex flex-col md:flex-row gap-10 ml-[10%] mr-[10%]">
            <h1 className="text-4xl flex flex-col items-start md:items-end w-[200px] font-nexa uppercase">
                <span className='text-primary-color'>Acerca</span>
                <span className='text-secondary-color'>de mi</span>
            </h1>
            <div className="flex flex-col">
                <h4 className="text-xl text-secondary-color font-medium">Hola, soy Manuel Vera</h4>
                <p className="mb-4 text-justify text-[1rem] text-primary-color">Digital Strategist con especialización en Growth Marketing.</p>
                <p className="mb-4 text-justify text-base">Llevo 13 años liderando el departamento de Paid Media, gestionando equipos de profesionales con los que implementamos campañas y estrategias para más de 100 empresas y marcas grandes y medianas, tales como, Samsung, Toyota, CasaIdeas, Adidas, Nike, Pedidos Ya, Arcos, Falabella, Warner música Argentina, grupo Televisa Argentina y muchos más.</p>
                <p className="mb-4 text-justify text-base">Certificado como Google Partner Profesional desde el 2011, trabajé en Google Latam realizando campañas digitales para posicionamiento e introducción de productos y marcas a mercados nuevos en Latinoamérica.</p>
                <p className="text-justify text-base">Me gusta enseñar, soy Speaker en varios eventos presenciales y virtuales, capacité a equipos de marketing de empresas líderes en el mercado, participe como coach en el Programa de Jóvenes Emprendedores, que realizó el Gobierno de la Ciudad de Buenos Aires.</p>
                <div className='flex flex-row justify-start gap-3 mt-4 [&>img]:cursor-pointer [&>a]:w-[30px]'>
                    <a href="https://www.facebook.com/vera.manu.f?mibextid=LQQJ4d" rel='noreferrer' target='_blank'>
                        <img className='hover:scale-125 transition-all duration-200 ease-out' src={FacebookIcon} alt="Facebook" />
                    </a>
                    <a href="https://www.linkedin.com/in/veramanuel" rel='noreferrer' target='_blank'>
                        <img className='hover:scale-125 transition-all duration-200 ease-out' src={LinkedinIcon} alt="LinkedIn" />
                    </a>
                    <a href="https://wa.me/+5491135060004" rel='noreferrer' target='_blank'>
                        <img className='hover:scale-125 transition-all duration-200 ease-out' src={WhatsappIcon} alt="Whatsapp" />
                    </a>
                </div>
            </div>
        </div>

        <div className="absolute bottom-0 left-0 w-full overflow-hidden rotate-[180deg]">
            <svg className="relative block w-[calc(279%_+_1.3px)] h-[150px]" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                <path fill="#EFF1F7" d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill"></path>
            </svg>
        </div>
    </div>
  )
}
