import ImgMyHistory from '../assets/images/img_my_history.svg';

export const MiHistory = () => {
  return (
    <section className="w-full h-auto flex flex-col justify-start pl-[10%] pr-[10%] pb-16">
        <h1 className='flex flex-col items-end md:w-[200px] text-4xl my-8 font-nexa uppercase'>
            <span className='text-primary-color'>Mi</span>
            <span className='text-secondary-color'>Historia</span>
        </h1>
        <div className="grid grid-cols-1 lg:grid-cols-4 gap-8">
            <img src={ImgMyHistory} alt="Mi historia" className='mx-auto lg:my-auto w-[300px]' />
            <div className='flex flex-col justify-start self-end [&>p]:text-sm'>
                <h1 className='text-secondary-color text-4xl'>2014</h1>
                <h2 className='font-medium'>Certificación en Marketing Móvil</h2>
                <p>Después de 2 años y medio en Google, me invitaron a trabajar en agencias de publicidad, donde la especialidad era las campañas para dispositivos móviles y Carrier como Claro, Personal y Telefonica. Lo que me llevo hacer una especialización en Marketing Movil.</p>
            </div>
            <div className='flex flex-col justify-start self-end [&>p]:text-sm'>
                <h1 className='text-secondary-color text-4xl'>2017</h1>
                <h2 className='font-medium'>Socio Partner Destacado de Google</h2>
                <p>Junto a SMID pude liderar el camino a que seamos la primera agencia en Bolivia como Google Partner, lo que represento certificaciones, inversiones y sobre todo mucho trabajo en campañas para alcanzar los estándares que exige Google.</p>
            </div>
            <div className='flex flex-col justify-start self-end [&>p]:text-sm'>
                <h1 className='text-secondary-color text-4xl'>2021</h1>
                <h2 className='font-medium'>Talento Gerencial</h2>
                <p>El semanario Bolian Business me incluye en la guía de Who is Who (guía de talento Gerencial) como Chief of Paid Media Strategy.</p>
            </div>
            <div className='my-16 hidden lg:col-span-4 w-[90%] ml-auto bg-gray-300 h-2 lg:flex flex-row justify-around rounded
                [&>span]:w-5 [&>span]:h-5 [&>span]:bg-secondary-color [&>span]:rounded-full [&>span]:mt-[-6px]'>
                <span className='relative before:content-[""] before:ml-[0.6rem] before:mt-6 before:w-1 before:h-14 before:border-l-[1px] before:border-dashed before:absolute before:border-gray-500'></span>
                <span className='relative before:content-[""] before:ml-[0.6rem] before:mb-6 before:w-1 before:h-14 before:border-l-[1px] before:border-dashed before:absolute before:bottom-0 before:border-gray-500'></span>
                <span className='relative before:content-[""] before:ml-[0.6rem] before:mt-6 before:w-1 before:h-14 before:border-l-[1px] before:border-dashed before:absolute before:border-gray-500'></span>
                <span className='relative before:content-[""] before:ml-[0.6rem] before:mb-6 before:w-1 before:h-14 before:border-l-[1px] before:border-dashed before:absolute before:bottom-0 before:border-gray-500'></span>
                <span className='relative before:content-[""] before:ml-[0.6rem] before:mt-6 before:w-1 before:h-14 before:border-l-[1px] before:border-dashed before:absolute before:border-gray-500'></span>
                <span className='relative before:content-[""] before:ml-[0.6rem] before:mb-6 before:w-1 before:h-14 before:border-l-[1px] before:border-dashed before:absolute before:bottom-0 before:border-gray-500'></span>
                <span className='relative before:content-[""] before:ml-[0.6rem] before:mt-6 before:w-1 before:h-14 before:border-l-[1px] before:border-dashed before:absolute before:border-gray-500'></span>
            </div>
            <div className='flex flex-col justify-start [&>p]:text-sm'>
                <h1 className='text-secondary-color text-4xl'>2012</h1>
                <h2 className='font-medium'>Google Partner Professional</h2>
                <p>Ingreso a trabajar en Google Latam, con certificación en Google Partner Professional.</p>
            </div>            
            <div className='flex flex-col justify-start [&>p]:text-sm'>
                <h1 className='text-secondary-color text-4xl'>2016</h1>
                <h2 className='font-medium'>Socio y Jefe de Paid Media en SMID</h2>
                <p>Juntamente con un emprendedor, nos asociamos para formar una agencia 100% digital, enfocada al Paid Media para Bolivia, que paso de ser una agencia de marketing a una Central de Medios Digitales.</p>
            </div>
            <div className='flex flex-col justify-start [&>p]:text-sm'>
                <h1 className='text-secondary-color text-4xl'>2019</h1>
                <h2 className='font-medium'>Charlas y Seminarios</h2>
                <p>Participe en el Samsung Lab, dando conferencia para influencers, tambien participe en el programa para jovenes emprendedores del Gobierno de la Ciudad de Buenos Aires como capacitador. Fue el inicio de conferencias y charlas para gerentes diversas empresas.</p>
            </div>
            <div className='flex flex-col justify-start [&>p]:text-sm'>
                <h1 className='text-secondary-color text-4xl'>2023</h1>
                <h2 className='font-medium'>Google Partner Premier</h2>
                <p>Junto a SMID, Google nos otorga la certificación como Google Partner Premier, formando parte del 3% de las mejores agencias, como expertos en Google Ads.</p>
            </div>
        </div>        
    </section>
  )
}
