import { Presentation } from "../components/presentation";
import { AboutMe } from "../components/about_me";
import { MyServices } from '../components/my_services';
import { MyWork } from '../components/my_work';
import { MiHistory } from '../components/my_history';
import { Customers } from '../components/customers';
import { Opinions } from '../components/opinions';
import { Contact } from '../components/contact';

export default function Home() {
  return (
    <main className="overflow-x-hidden">
        <Presentation />
        <AboutMe />
        <MyServices />
        <MyWork />
        <MiHistory />
        <Customers />
        <Opinions />
        <Contact />
    </main>
  )
}
