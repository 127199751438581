import { Splide, SplideSlide } from '@splidejs/react-splide';

import Al_1 from '../assets/images/brands_worked/alimentos/Alicorp (negativo).png';
import Al_2 from '../assets/images/brands_worked/alimentos/Arcor  (negativo).png';
import Al_3 from '../assets/images/brands_worked/alimentos/Cruze del zorro (negativo).png';
import Al_4 from '../assets/images/brands_worked/alimentos/Escuela Venado (negativo).png';
import Al_5 from '../assets/images/brands_worked/alimentos/Hipermaxi (logo).png';
import Al_6 from '../assets/images/brands_worked/alimentos/iurpana (negativo).png';
import Al_7 from '../assets/images/brands_worked/alimentos/Opal (negativo).png';

import App_1 from '../assets/images/brands_worked/apps/Barra 30 (neg).png';
import App_2 from '../assets/images/brands_worked/apps/Bolivia en un clcik (NEG).png';
import App_3 from '../assets/images/brands_worked/apps/Chamba (NEGATIVO).png';
import App_4 from '../assets/images/brands_worked/apps/PedidosYa (NEG).png';
import App_5 from '../assets/images/brands_worked/apps/tIGO (NEG).png';
import App_6 from '../assets/images/brands_worked/apps/yolopago (NEG).png';

import Auto_1 from '../assets/images/brands_worked/automoviles/Autokorp (neg).png';
import Auto_2 from '../assets/images/brands_worked/automoviles/Autosud (NEG).png';
import Auto_3 from '../assets/images/brands_worked/automoviles/Finilanger (neg).png';
import Auto_4 from '../assets/images/brands_worked/automoviles/Kia (neg).png';
import Auto_5 from '../assets/images/brands_worked/automoviles/Suzuki (NEGATIVO).png';
import Auto_6 from '../assets/images/brands_worked/automoviles/Toyota (NEG).png';

import Banco_1 from '../assets/images/brands_worked/bancos/Banco Ganadero (neg).png';
import Banco_2 from '../assets/images/brands_worked/bancos/Banco Mercantil(negativo).png';
import Banco_3 from '../assets/images/brands_worked/bancos/Bisa (negativo).png';

import Dep_1 from '../assets/images/brands_worked/deportes/Adidas (neg).png';
import Dep_2 from '../assets/images/brands_worked/deportes/Fairplay(neg).png';
import Dep_3 from '../assets/images/brands_worked/deportes/TOPPER (neg).png';
import Dep_4 from '../assets/images/brands_worked/deportes/Unerarmor (neg).png';

import Disp_1 from '../assets/images/brands_worked/disp moviles/CLARO (neg).png';
import Disp_2 from '../assets/images/brands_worked/disp moviles/logo-Samsung (neg).png';
import Disp_3 from '../assets/images/brands_worked/disp moviles/MOVISTAR (neg).png';
import Disp_4 from '../assets/images/brands_worked/disp moviles/PERSONAL (neg).png';

import Eco_1 from '../assets/images/brands_worked/ecommerce/Casa ideas (NEG).png';
import Eco_2 from '../assets/images/brands_worked/ecommerce/DISMAC (NEG).png';
import Eco_3 from '../assets/images/brands_worked/ecommerce/Estropical (neg).png';
import Eco_4 from '../assets/images/brands_worked/ecommerce/Eya va (NEG).png';
import Eco_5 from '../assets/images/brands_worked/ecommerce/Fallabella (neg).png';
import Eco_6 from '../assets/images/brands_worked/ecommerce/ICG (neg).png';
import Eco_7 from '../assets/images/brands_worked/ecommerce/Logo 2022 (negativo).png';
import Eco_8 from '../assets/images/brands_worked/ecommerce/Maxiking (neg).png';
import Eco_9 from '../assets/images/brands_worked/ecommerce/Multicenter (neg).png';
import Eco_10 from '../assets/images/brands_worked/ecommerce/tIENDA AMIGA (neg).png';

import Edu_1 from '../assets/images/brands_worked/educacion/ubc (neg).png';
import Edu_2 from '../assets/images/brands_worked/educacion/Univ Privada (neg).png';
import Edu_3 from '../assets/images/brands_worked/educacion/universidad del valle (NEG).png';
import Edu_4 from '../assets/images/brands_worked/educacion/Upsa (neg).png';
import Edu_5 from '../assets/images/brands_worked/educacion/UVIRTUAL (neg).png';

import Enpu_1 from '../assets/images/brands_worked/entidad publica/MINISTERIO DE DESARROLLO (neg).png';
import Enpu_2 from '../assets/images/brands_worked/entidad publica/MMAYA (NEG).png';
import Enpu_3 from '../assets/images/brands_worked/entidad publica/saguapac (NEG).png';

import Ev_1 from '../assets/images/brands_worked/eventos/cinemark (NEG).png';
import Ev_2 from '../assets/images/brands_worked/eventos/fexo (NEG).png';
import Ev_3 from '../assets/images/brands_worked/eventos/Team Link (NEG).png';
import Ev_4 from '../assets/images/brands_worked/eventos/universal (NEG).png';
import Ev_5 from '../assets/images/brands_worked/eventos/warner (NEG).png';

import Ind_1 from '../assets/images/brands_worked/industria/Madepa (neg).png';
import Ind_2 from '../assets/images/brands_worked/industria/monopol (NEG).png';
import Ind_3 from '../assets/images/brands_worked/industria/soboce (NEG).png';

import Inmob_1 from '../assets/images/brands_worked/inmobiliaria/Green Tower (neg).png';
import Inmob_2 from '../assets/images/brands_worked/inmobiliaria/Holiday (NEG).png';
import Inmob_3 from '../assets/images/brands_worked/inmobiliaria/Iconica LOGOO (NEG).png';
import Inmob_4 from '../assets/images/brands_worked/inmobiliaria/inbysmart (NEG).png';
import Inmob_5 from '../assets/images/brands_worked/inmobiliaria/m40 (NEG).png';
import Inmob_6 from '../assets/images/brands_worked/inmobiliaria/mar adentro (NEG).png';
import Inmob_7 from '../assets/images/brands_worked/inmobiliaria/miradores (NEG).png';
import Inmob_8 from '../assets/images/brands_worked/inmobiliaria/norte verde (NEG).png';
import Inmob_9 from '../assets/images/brands_worked/inmobiliaria/remax (NEG).png';
import Inmob_10 from '../assets/images/brands_worked/inmobiliaria/URBO (NEG).png';
import Inmob_11 from '../assets/images/brands_worked/inmobiliaria/you (neg).png';

import Com_1 from '../assets/images/brands_worked/medios comunicacion/Bolivian Business (neg).png';
import Com_2 from '../assets/images/brands_worked/medios comunicacion/cool (neg).png';
import Com_3 from '../assets/images/brands_worked/medios comunicacion/cosmo (neg).png';
import Com_4 from '../assets/images/brands_worked/medios comunicacion/datos (neg).png';
import Com_5 from '../assets/images/brands_worked/medios comunicacion/gente (neg).png';
import Com_6 from '../assets/images/brands_worked/medios comunicacion/parati (NEG).png';
import Com_7 from '../assets/images/brands_worked/medios comunicacion/televisia (neg).png';
import Com_8 from '../assets/images/brands_worked/medios comunicacion/unos (NEG).png';

import Rest_1 from '../assets/images/brands_worked/restaurante/El arriero (neg).png';
import Rest_2 from '../assets/images/brands_worked/restaurante/goss (NEG).png';
import Rest_3 from '../assets/images/brands_worked/restaurante/KFC logo (NEG).png';
import Rest_4 from '../assets/images/brands_worked/restaurante/pIZZA (NEG).png';

import Sal_1 from '../assets/images/brands_worked/salud/hp (NEG).png';
import Sal_2 from '../assets/images/brands_worked/salud/megalab (NEG).png';
import Sal_3 from '../assets/images/brands_worked/salud/neurocenter (NEG).png';
import Sal_4 from '../assets/images/brands_worked/salud/orongo (NEG).png';

export const Customers = () => {
    return (
        <section className="w-full h-auto relative bg-customers bg-top bg-cover">
            <div className='w-fit text-center h-auto text-2xl md:text-4xl mx-auto pb-2 px-8 md:px-16 md:py-2 text-white bg-title-lg bg-no-repeat bg-contain'>
                <div className='md:-translate-y-1'>Marcas trabajadas</div>
            </div>
            <div className='w-full pt-16 pb-16'>
                <Splide
                    options={{
                        perPage: 1,
                        type: 'loop',
                        // direction: 'ttb',
                        // height   : '30rem',
                        // autoWidth: true,
                        autoplay: true,
                        // focus:'center',
                        // gap: 30,
                        interval: 4000,
                        easing: 'cubic-bezier(0.22, 1, 0.36, 1)',
                        speed: 800,
                        padding: '0rem',
                        arrows: false,
                        pagination: false,
                        breakpoints: {
                            1600: {
                                perPage: 1
                            },
                            1024: {
                                perPage: 1
                            },
                            780: {
                                perPage: 1
                            }
                        },
                    }}
                >
                    <SplideSlide>
                        <div className='pl-[10%] pr-[10%]'>
                            <h1 className='text-4xl text-secondary-color text-center'>Alimentos y Bebidas</h1>
                            <div className='flex flex-row items-center flex-wrap justify-center py-16 gap-8 [&>img]:w-[120px] [&>img]:h-fit'>
                                <img src={Al_1} alt="Alicorp" />
                                <img src={Al_2} alt="Arcor" />
                                <img src={Al_3} alt="Cruze del zorro" />
                                <img src={Al_4} alt="Escuela venado" />
                                <img src={Al_5} alt="Hipermaxi" />
                                <img src={Al_6} alt="Irupana" />
                                <img src={Al_7} alt="Opal" />
                            </div>
                        </div>
                    </SplideSlide>
                    <SplideSlide>
                        <div className='pl-[10%] pr-[10%]'>
                            <h1 className='text-4xl text-secondary-color text-center'>Apps</h1>
                            <div className='flex flex-row items-center flex-wrap justify-center py-16 gap-8 [&>img]:w-[120px] [&>img]:h-fit'>
                                <img src={App_1} alt="Barra 30" />
                                <img src={App_2} alt="Bolivia en un click" />
                                <img src={App_3} alt="Chamba" />
                                <img src={App_4} alt="Pedidos Ya" />
                                <img src={App_5} alt="Tigo" />
                                <img src={App_6} alt="Yolopago" />
                            </div>
                        </div>
                    </SplideSlide>
                    <SplideSlide>
                        <div className='pl-[10%] pr-[10%]'>
                            <h1 className='text-4xl text-secondary-color text-center'>Automóviles</h1>
                            <div className='flex flex-row items-center flex-wrap justify-center py-16 gap-8 [&>img]:w-[120px] [&>img]:h-fit'>
                                <img src={Auto_1} alt="Autokorp" />
                                <img src={Auto_2} alt="Autosud" />
                                <img src={Auto_3} alt="Finilanger" />
                                <img src={Auto_4} alt="Kia" />
                                <img src={Auto_5} alt="Suzuki" />
                                <img src={Auto_6} alt="Toyota" />
                            </div>
                        </div>
                    </SplideSlide>
                    <SplideSlide>
                        <div className='pl-[10%] pr-[10%]'>
                            <h1 className='text-4xl text-secondary-color text-center'>Bancos</h1>
                            <div className='flex flex-row items-center flex-wrap justify-center py-16 gap-8 [&>img]:w-[180px] [&>img]:h-fit'>
                                <img src={Banco_1} alt="Banco Ganadero" />
                                <img src={Banco_2} alt="Banco Mercantil" />
                                <img src={Banco_3} alt="Banco Bisa" />
                            </div>
                        </div>
                    </SplideSlide>
                    <SplideSlide>
                        <div className='pl-[10%] pr-[10%]'>
                            <h1 className='text-4xl text-secondary-color text-center'>Deportes</h1>
                            <div className='flex flex-row items-center flex-wrap justify-center py-16 gap-8 [&>img]:w-[120px] [&>img]:h-fit'>
                                <img src={Dep_1} alt="Adidas" />
                                <img src={Dep_2} alt="Fairplay" />
                                <img src={Dep_3} alt="Topper" />
                                <img src={Dep_4} alt="Unerarmor" />
                            </div>
                        </div>
                    </SplideSlide>
                    <SplideSlide>
                        <div className='pl-[10%] pr-[10%]'>
                            <h1 className='text-4xl text-secondary-color text-center'>Dispositivos móviles</h1>
                            <div className='flex flex-row items-center flex-wrap justify-center py-16 gap-8 [&>img]:w-[120px] [&>img]:h-fit'>
                                <img src={Disp_1} alt="Claro" />
                                <img src={Disp_2} alt="Samsung" />
                                <img src={Disp_3} alt="Movistar" />
                                <img src={Disp_4} alt="Personal" />
                            </div>
                        </div>
                    </SplideSlide>
                    <SplideSlide>
                        <div className='pl-[10%] pr-[10%]'>
                            <h1 className='text-4xl text-secondary-color text-center'>Ecommerce</h1>
                            <div className='flex flex-row items-center flex-wrap justify-center py-16 gap-8 [&>img]:w-[120px] [&>img]:h-fit'>
                                <img src={Eco_1} alt="Casa Ideas" />
                                <img src={Eco_2} alt="Dismac" />
                                <img src={Eco_3} alt="Estropical" />
                                <img src={Eco_4} alt="Eya va" />
                                <img src={Eco_5} alt="Fallabella" />
                                <img src={Eco_6} alt="ICG" />
                                <img src={Eco_7} alt="Escuela Gourmet" />
                                <img src={Eco_8} alt="MaxKing" />
                                <img src={Eco_9} alt="Multicenter" />
                                <img src={Eco_10} alt="Tienda Amiga" />
                            </div>
                        </div>
                    </SplideSlide>
                    <SplideSlide>
                        <div className='pl-[10%] pr-[10%]'>
                            <h1 className='text-4xl text-secondary-color text-center'>Educación</h1>
                            <div className='flex flex-row items-center flex-wrap justify-center py-16 gap-8 [&>img]:w-[120px] [&>img]:h-fit'>
                                <img src={Edu_1} alt="UBC" />
                                <img src={Edu_2} alt="U. Domingo Savio" />
                                <img src={Edu_3} alt="U. del Valle" />
                                <img src={Edu_4} alt="UPSA" />
                                <img src={Edu_5} alt="U. Virtual" />
                            </div>
                        </div>
                    </SplideSlide>
                    <SplideSlide>
                        <div className='pl-[10%] pr-[10%]'>
                            <h1 className='text-4xl text-secondary-color text-center'>Entidades Públicas</h1>
                            <div className='flex flex-row items-center flex-wrap justify-center py-16 gap-8 [&>img]:w-[120px] [&>img]:h-fit'>
                                <img src={Enpu_1} alt="Ministerio de Desarrollo" />
                                <img src={Enpu_2} alt="MMaya" />
                                <img src={Enpu_3} alt="Saguapac" />
                            </div>
                        </div>
                    </SplideSlide>
                    <SplideSlide>
                        <div className='pl-[10%] pr-[10%]'>
                            <h1 className='text-4xl text-secondary-color text-center'>Eventos</h1>
                            <div className='flex flex-row items-center flex-wrap justify-center py-16 gap-8 [&>img]:w-[120px] [&>img]:h-fit'>
                                <img src={Ev_1} alt="Cinemark" />
                                <img src={Ev_2} alt="Fexo" />
                                <img src={Ev_3} alt="Team Link" />
                                <img src={Ev_4} alt="Universal" />
                                <img src={Ev_5} alt="Warner" />
                            </div>
                        </div>
                    </SplideSlide>
                    <SplideSlide>
                        <div className='pl-[10%] pr-[10%]'>
                            <h1 className='text-4xl text-secondary-color text-center'>Industria</h1>
                            <div className='flex flex-row items-center flex-wrap justify-center py-16 gap-8 [&>img]:w-[120px] [&>img]:h-fit'>
                                <img src={Ind_1} alt="Madepa" />
                                <img src={Ind_2} alt="Monopol" />
                                <img src={Ind_3} alt="SOBOCE" />
                            </div>
                        </div>
                    </SplideSlide>
                    <SplideSlide>
                        <div className='pl-[10%] pr-[10%]'>
                            <h1 className='text-4xl text-secondary-color text-center'>Inmobiliaria</h1>
                            <div className='flex flex-row items-center flex-wrap justify-center py-16 gap-8 [&>img]:w-[120px] [&>img]:h-fit'>
                                <img src={Inmob_1} alt="Green Tower" />
                                <img src={Inmob_2} alt="Holiday" />
                                <img src={Inmob_3} alt="Iconica" />
                                <img src={Inmob_4} alt="Inbysmart" />
                                <img src={Inmob_5} alt="M40" />
                                <img src={Inmob_6} alt="Mar Adentro" />
                                <img src={Inmob_7} alt="Miradores" />
                                <img src={Inmob_8} alt="Norte Verde" />
                                <img src={Inmob_9} alt="Remax" />
                                <img src={Inmob_10} alt="Urubó" />
                                <img src={Inmob_11} alt="You" />
                            </div>
                        </div>
                    </SplideSlide>
                    <SplideSlide>
                        <div className='pl-[10%] pr-[10%]'>
                            <h1 className='text-4xl text-secondary-color text-center'>Medios de Comunicación</h1>
                            <div className='flex flex-row items-center flex-wrap justify-center py-16 gap-8 [&>img]:w-[120px] [&>img]:h-fit'>
                                <img src={Com_1} alt="Bolivian Business" />
                                <img src={Com_2} alt="Cool" />
                                <img src={Com_3} alt="Cosmo" />
                                <img src={Com_4} alt="Datos" />
                                <img src={Com_5} alt="Gente" />
                                <img src={Com_6} alt="Parati" />
                                <img src={Com_7} alt="Televisa" />
                                <img src={Com_8} alt="UNO" />
                            </div>
                        </div>
                    </SplideSlide>
                    <SplideSlide>
                        <div className='pl-[10%] pr-[10%]'>
                            <h1 className='text-4xl text-secondary-color text-center'>Restaurantes</h1>
                            <div className='flex flex-row items-center flex-wrap justify-center py-16 gap-8 [&>img]:w-[120px] [&>img]:h-fit'>
                                <img src={Rest_1} alt="El Arriero" />
                                <img src={Rest_2} alt="GOSS" />
                                <img src={Rest_3} alt="KFC" />
                                <img src={Rest_4} alt="Pizza Kitchen" />
                            </div>
                        </div>
                    </SplideSlide>
                    <SplideSlide>
                        <div className='pl-[10%] pr-[10%]'>
                            <h1 className='text-4xl text-secondary-color text-center'>Salud</h1>
                            <div className='flex flex-row items-center flex-wrap justify-center py-16 gap-8 [&>img]:w-[120px] [&>img]:h-fit'>
                                <img src={Sal_1} alt="HP" />
                                <img src={Sal_2} alt="Megalabs" />
                                <img src={Sal_3} alt="NeuroCenter" />
                                <img src={Sal_4} alt="ORGONO" />
                            </div>
                        </div>
                    </SplideSlide>
                </Splide>
            </div>

            <div className='w-full overflow-hidden'>
                <svg className='absolute translate-y-1 bottom-0 block w-[calc(300%_+_1.3px)] h-[150px]' data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                    <path fill='white' d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" className="shape-fill"></path>
                </svg>
            </div>
        </section>
    )
}
