import Ring from '../assets/images/ring.png';

export const MyWork = () => {
  return (
    <section className="relative w-full h-auto overflow-hidden bg-my-work pb-52 font-roboto">
        <div className='w-fit text-center h-auto text-2xl md:text-4xl mx-auto px-8 md:px-16 md:py-2 text-white bg-title bg-no-repeat bg-contain'>
            Mi trabajo
        </div>
        <div className='grid grid-cols-1 lg:grid-cols-2 gap-x-8 gap-y-16 pt-12 md:pt-20 ml-[10%] mr-[10%]'>
            <div className='flex flex-row gap-4'>
                <div className='relative h-[130px]'>
                    <img src={Ring} alt="Aro" className='w-[130px] max-w-none' />
                    <span className='absolute left-[50%] translate-x-[-50%] top-[50%] translate-y-[-50%] text-3xl text-white'>+120</span>
                </div>
                <div className='flex flex-col justify-start'>
                    <h1 className='text-secondary-color'>Empresas y marcas con estrategia y pauta</h1>
                    <p className='text-white text-justify'>Diseñe, dirigí e implemente estrategias y pauta digital para empresas y marcas como Samsung Bolivia, Warner Music Argentina, Casa Ideas, Falabella Chile, Universal, entre muchas.</p>
                </div>
            </div>
            <div className='flex flex-row gap-4'>
                <div className='relative h-[130px]'>
                    <img src={Ring} alt="Aro" className='w-[130px] max-w-none' />
                    <span className='absolute left-[50%] translate-x-[-50%] top-[50%] translate-y-[-50%] text-3xl text-white'>+20</span>
                </div>
                <div className='flex flex-col justify-start'>
                    <h1 className='text-secondary-color'>Rubros empresariales con estrategia y pauta</h1>
                    <p className='text-white text-justify'>Diseñe, dirigí e implemente estrategias y pauta digital para rubros/verticales tales como Alimentos y Bebidas, App, Automóvil, Bancos, Ecommerce, Educación, Inmobiliario, Tecnología, entre otras.</p>
                </div>
            </div>
            <div className='flex flex-row gap-4'>
                <div className='relative h-[130px]'>
                    <img src={Ring} alt="Aro" className='w-[130px] max-w-none' />
                    <span className='absolute left-[50%] translate-x-[-50%] top-[50%] translate-y-[-50%] text-3xl text-white'>+1200</span>
                </div>
                <div className='flex flex-col justify-start'>
                    <h1 className='text-secondary-color'>Alumnos y emprendedores participaron de mis charlas</h1>
                    <p className='text-white text-justify'>Dicté cursos, charlas, asesoramiento a emprendedores en el programa del Gobierno de la ciudad de Buenos Aires, capacitaciones privadas y charlas masivas.</p>
                </div>
            </div>
            <div className='flex flex-row gap-4'>
                <div className='relative h-[130px]'>
                    <img src={Ring} alt="Aro" className='w-[130px] max-w-none' />
                    <span className='absolute left-[50%] translate-x-[-50%] top-[50%] translate-y-[-50%] text-3xl text-white'>+11</span>
                </div>
                <div className='flex flex-col justify-start'>
                    <h1 className='text-secondary-color'>Años como marketer digital</h1>
                    <p className='text-white text-justify'>Desde el 2011 que me especialice en Marketing Digital y Marketing Móvil en la universidad de Buenos Aires y Universidad de Palermo en Argentina.</p>
                </div>
            </div>
            <div className='flex flex-row gap-4'>
                <div className='relative h-[130px]'>
                    <img src={Ring} alt="Aro" className='w-[130px] max-w-none' />
                    <span className='absolute left-[50%] translate-x-[-50%] top-[50%] translate-y-[-50%] text-3xl text-white'>+10</span>
                </div>
                <div className='flex flex-col justify-start'>
                    <h1 className='text-secondary-color'>Años como Google Partner Professional</h1>
                    <p className='text-white text-justify'>El 2012 me certifique como Google Partner Professional, con renovación anual, para luego ingresar a trabajar en Google Latam.</p>
                </div>
            </div>
            <div className='flex flex-row gap-4'>
                <div className='relative h-[130px]'>
                    <img src={Ring} alt="Aro" className='w-[130px] max-w-none' />
                    <span className='absolute left-[50%] translate-x-[-50%] top-[50%] translate-y-[-50%] text-2xl text-white'>+20USD millones</span>
                </div>
                <div className='flex flex-col justify-start'>
                    <h1 className='text-secondary-color'>Administrados en pauta</h1>
                    <p className='text-white text-justify'>Inversiones en pautas digitales en Google, Facebook, Linkedin, Twitter y otras plataformas, para campañas de branding y performance.</p>
                </div>
            </div>
        </div>
        
        <div className='w-full overflow-hidden'>
            <svg className='absolute bottom-0 block w-[calc(300%_+_1.3px)] h-[150px]' data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                <path fill='white' d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" className="shape-fill"></path>
            </svg>
        </div>
    </section>
  )
}
