import { useLoaderData } from "react-router-dom";
import { getCardData, getVCard } from "../api/card_data";
import { Button } from "@material-tailwind/react";
import EmailIcon from '@mui/icons-material/Email';
import { WhatsApp } from "@mui/icons-material";
// import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
// import WppIcon from '../assets/images/whatsapp_icon.png';

export async function loader() {
	const data = await getCardData();
	return data;
}

// export async function action({request}){
// 	const formData = await request.formData();
// 	const datos = Object.fromEntries(formData);
// 	const email = formData.get('email');
// 	console.log(datos);

// 	// Validaciones
// 	const errores=[];
// 	if(Object.values(datos).includes('')){
// 		errores.push('Todos los campos son obligatorios');
// 	}
// 	let regex = new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i);
// 	if(!regex.test(email)){
// 		errores.push('El email no es válido');
// 	}

// 	// Retornar datos si hay errores
// 	if(Object.keys(errores).length){
// 		return errores
// 	}

// 	return true;
// }

export default function VCard() {
	// const errores = useActionData();
	const data = useLoaderData();
	console.log('card_data', data);

	const downloadVCard = async () => {
		const vcard = await getVCard();

		var url = window.URL.createObjectURL(vcard),
			anchor = document.createElement("a");
		anchor.href = url;
		anchor.download = 'manuvera';
		anchor.click();

		window.URL.revokeObjectURL(url);
		// document.removeChild(anchor);
		return vcard;
	}

	const openWhatsapp = (number, message) =>{
		const enlace = `https://api.whatsapp.com/send?phone=${number.toString().replace("+", "").trim()}&text=${message}`;
		window.open(enlace, "_blank");
	}

	return (
		<main className="w-full h-screen px-10 pt-8 md:py-20 bg-gray-400 bg-gradient-to-tl from-[#1189ce] to-primary-color overflow-y-auto">

			<section className="mx-auto mb-6 md:mb-10 w-36 h-36 md:w-48 md:h-48 bg-transparent overflow-hidden relative">
				<img className="object-cover w-48 h-full z-0 rounded-full border-8" src={process.env.REACT_APP_API_URL + '/get-picture-profile/' + data.profile_picture} alt="Foto de perfil" />
			</section>

			<section className="w-full flex justify-center">
				<Button className="shadow-none text-base capitalize font-medium text-white mx-auto w-40 h-12 rounded-full bg-secondary-color hover:w-52 hover:shadow-none" 
					onClick={() => downloadVCard()}>
					Agéndame
				</Button>
			</section>

			<section className="w-full h-auto mb-8 md:mb-16 relative flex pt-8 md:pt-16 md:items-start items-center justify-center">
				<div className="flex flex-col items-center">
					<h1 className="font-nexa text-2xl md:text-4xl text-white flex items-center gap-2">
						<span>{data.name}</span>
						<span>{data.first_surname}</span>
					</h1>
					<h2 className="font-roboto text-base md:text-xl text-white text-center">{data.profession}</h2>
				</div>
			</section>

			<section className="flex flex-col w-fit mx-auto mb-4 md:mb-10">
				<h2 className="text-xl uppercase text-center text-gray-400 pb-4">Social Media</h2>
				<div className="flex flex-wrap gap-4 md:gap-8 justify-center">
					{
						data.url_facebook &&
						<a className="text-sm md:text-base cursor-pointer flex items-center gap-2 text-white" href={data.url_facebook} target="_blank" rel="noreferrer">
							<svg className="w-6 md:w-8" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
								<path className="fill-secondary-color"
									d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z" />
							</svg>
							Facebook
						</a>
					}
					{
						data.url_youtube &&
						<a className="text-sm md:text-base cursor-pointer flex items-center gap-2 text-white" href={data.url_youtube} target="_blank" rel="noreferrer">
							<svg className="w-6 md:w-8" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
								<path className="fill-secondary-color"
									d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z" />
							</svg>
							YouTube
						</a>
					}
					{
						data.url_instagram &&
						<a className="text-sm md:text-base cursor-pointer flex items-center gap-2 text-white" href={data.url_instagram} target="_blank" rel="noreferrer">
							<svg className="w-6 md:w-8" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
								<path className="fill-secondary-color"
									d="M224,202.66A53.34,53.34,0,1,0,277.36,256,53.38,53.38,0,0,0,224,202.66Zm124.71-41a54,54,0,0,0-30.41-30.41c-21-8.29-71-6.43-94.3-6.43s-73.25-1.93-94.31,6.43a54,54,0,0,0-30.41,30.41c-8.28,21-6.43,71.05-6.43,94.33S91,329.26,99.32,350.33a54,54,0,0,0,30.41,30.41c21,8.29,71,6.43,94.31,6.43s73.24,1.93,94.3-6.43a54,54,0,0,0,30.41-30.41c8.35-21,6.43-71.05,6.43-94.33S357.1,182.74,348.75,161.67ZM224,338a82,82,0,1,1,82-82A81.9,81.9,0,0,1,224,338Zm85.38-148.3a19.14,19.14,0,1,1,19.13-19.14A19.1,19.1,0,0,1,309.42,189.74ZM400,32H48A48,48,0,0,0,0,80V432a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V80A48,48,0,0,0,400,32ZM382.88,322c-1.29,25.63-7.14,48.34-25.85,67s-41.4,24.63-67,25.85c-26.41,1.49-105.59,1.49-132,0-25.63-1.29-48.26-7.15-67-25.85s-24.63-41.42-25.85-67c-1.49-26.42-1.49-105.61,0-132,1.29-25.63,7.07-48.34,25.85-67s41.47-24.56,67-25.78c26.41-1.49,105.59-1.49,132,0,25.63,1.29,48.33,7.15,67,25.85s24.63,41.42,25.85,67.05C384.37,216.44,384.37,295.56,382.88,322Z" />
							</svg>
							Instagram
						</a>
					}
					{
						data.url_twiter &&
						<a className="text-sm md:text-base cursor-pointer flex items-center gap-2 text-white" href={data.url_twiter} target="_blank" rel="noreferrer">
							<svg className="w-8 md:w-12" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1668.56 1221.19">
								<g transform="translate(52.390088,-25.058597)">
									<path className="fill-secondary-color" d="M283.94,167.31l386.39,516.64L281.5,1104h87.51l340.42-367.76L984.48,1104h297.8L874.15,558.3l361.92-390.99
		h-87.51l-313.51,338.7l-253.31-338.7H283.94z M412.63,231.77h136.81l604.13,807.76h-136.81L412.63,231.77z"/>
								</g>
							</svg>
							Twitter
						</a>
					}
					{
						data.url_linkedin &&
						<a className="text-sm md:text-base cursor-pointer flex items-center gap-2 text-white" href={data.url_linkedin} target="_blank" rel="noreferrer">
							<svg className="w-6 md:w-8" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
								<path className="fill-secondary-color"
									d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z" />
							</svg>
							LinkedIn
						</a>
					}
				</div>
			</section>

			<section className="flex flex-col w-fit mx-auto  mb-10">
				<h2 className="text-xl uppercase text-center text-gray-400 pb-4">Contact</h2>
				<div className="flex flex-wrap gap-4 md:gap-8 justify-center">
					{
						data.email &&
						<a className="flex gap-2 items-center text-white" href={'mailto: ' + data.email} target="_blank" rel="noreferrer">
							<EmailIcon className="text-secondary-color" />
							<span>{data.email}</span>
						</a>
					}
					{
						data.mobile &&
						<button className="flex gap-2 items-center text-white" onClick={()=>openWhatsapp(data.mobile, data.text_whatsapp)}>
							<WhatsApp className="text-secondary-color" />
							<span>{data.mobile}</span>
						</button>
					}
				</div>
			</section>

		</main>
	)
}
