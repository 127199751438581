import LogoManuColor from '../assets/images/logomanu_color.png';
import FacebookIcon from '../assets/images/facebook_icon.png';
import LinkedinIcon from '../assets/images/linkedin_icon.png';
import WhatsappIcon from '../assets/images/whatsapp_icon.png';

export const Contact = () => {
  return (
    <section className="w-full h-auto bg-[#e7ecf4] py-[5rem]">
        <div className="grid grid-cols-1 lg:grid-cols-3 pl-[10%] pr-[10%] gap-y-10 lg:gap-5">
            <img className='order-3 lg:order-1 w-40 mx-auto' src={LogoManuColor} alt="ManuVera" />
            <div className='order-2 lg:order-2 flex flex-col justify-center items-center lg:items-start'>
                <h1 className='text-secondary-color font-medium text-2xl'>Contact</h1>
                <div className='flex flex-row items-start'>
                    <h2 className='font-medium'>Direccion:</h2>
                    <p>Rivadavia 3419, CABA, Argentina</p>
                </div>
                <div className='flex flex-row items-start'>
                    <h2 className='font-medium'>Email:</h2>
                    <p>manuel@manuvera.online</p>
                </div>
                <div className='flex flex-row items-start'>
                    <h2 className='font-medium'>Cel:</h2>
                    <p>+54 91135060004</p>
                </div>
            </div>
            <div className='order-2 lg:order-3 flex flex-row items-center justify-center'>                
                <div className='flex flex-row justify-start items-center gap-3 [&>img]:cursor-pointer [&>a]:w-[30px]'>
                    <a href="https://www.facebook.com/vera.manu.f?mibextid=LQQJ4d" rel='noreferrer' target='_blank'>
                        <img className='hover:scale-125 transition-all duration-200 ease-out' src={FacebookIcon} alt="Facebook" />
                    </a>
                    <a href="https://www.linkedin.com/in/veramanuel" rel='noreferrer' target='_blank'>
                        <img className='hover:scale-125 transition-all duration-200 ease-out' src={LinkedinIcon} alt="LinkedIn" />
                    </a>
                    <a href="https://wa.me/+5491135060004" rel='noreferrer' target='_blank'>
                        <img className='hover:scale-125 transition-all duration-200 ease-out' src={WhatsappIcon} alt="Whatsapp" />
                    </a>
                </div>
            </div>
        </div>
    </section>
  )
}
