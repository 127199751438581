import Check from '../assets/images/check.png'
import ImgMyServices from  '../assets/images/img_my_services.svg'

export const MyServices = () => {
  return (
    <section className="bg-[#EFF1F7] w-full h-auto pb-24">

        <div className="flex flex-col lg:flex-row gap-10 ml-[10%] mr-[10%]">
            <div className='flex flex-row items-start md:flex-col md:items-center'>
                <h1 className="text-2xl md:text-4xl flex flex-col items-end w-[200px] font-nexa uppercase">
                    <span className='text-primary-color'>Mis</span>
                    <span className='text-secondary-color'>servicios</span>
                </h1>
                <img className='w-[180px] md:w-[300px] max-w-none' src={ImgMyServices} alt="Mis servicios" />
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-x-2 gap-y-8">
                <div className="flex flex-row items-start gap-2">
                    <img className='w-5 mt-[0.3rem]' src={Check} alt="check" />
                    <div className="flex flex-col justify-start [&>p]:mb-2">
                        <h1 className='text-xl font-medium'>Estrategia digital, Growth marketing y Growth Loop</h1>
                        <p>Quieres que tu negocio crezca en línea? Con  mi servicio de Growth Marketing, te ayudo a desarrollar una estrategia digital que te permita destacar en línea, aumentar tus clientes, mejorar tu presencia en línea y aumentar tu ROI.</p>
                        <p>El servicio incluye:</p>
                        <ul className='ml-8 mb-2 [&>li]:list-disc'>
                            <li>Desarrollo de una estrategia digital</li>
                            <li>Definición de objetivos claros, medibles y formas de optimización</li>
                            <li>Creación de un plan de acción personalizado</li>
                            <li>Implementación de la estrategia digital</li>
                            <li>Seguimiento y análisis de los resultados</li>
                        </ul>
                        <p>También ofrecemos servicios de Growth Loop, una estrategia de crecimiento que se basa en crear un ciclo de retroalimentación positivo y repetitivo, permitiendo mantener un flujo de crecimiento constante.</p>
                        <p>Si estás listo para empezar a crecer, contactame hoy mismo para obtener más información sobre nuestros servicios.</p>
                        <p>¡Te ayudaremos a hacer despegar tu negocio en el mundo digital!</p>
                    </div>
                </div>
                <div className="flex flex-row items-start gap-2">
                    <img className='w-5 mt-[0.3rem]' src={Check} alt="check" />
                    <div className="flex flex-col justify-start [&>p]:mb-2">
                        <h1 className='text-xl font-medium'>Auditoría ecosistema digital</h1>
                        <p>¿Tienes tu sitio Web y perfiles en Redes Sociales? Pero no sabes porque no funcionan y no te generan ventas? ¿Quieres mejorar tu presencia en línea</p>
                        <p>¡Entonces solicita una auditoría de canales digitales y redes sociales hoy mismo!</p>
                        <p>Con lo que podrás identificar y corregir cualquier problema en tus actividad digitales ( web, RRSS, etc) y garantizar la seguridad brindando la mejor experiencia para tus clientes.</p>
                        <p>¡No esperes más para proteger tu negocio en línea , solicita una auditoría de tú ecosistema digital ahora mismo!</p>
                        <p>Nuestros auditores expertos analizarán tu sitio web, perfiles de redes sociales y campañas de marketing digital para identificar áreas donde puedes mejorar tu rendimiento. Con nuestra ayuda, puedes aumentar el tráfico a tu sitio web, generar más leads y mejorar tus relaciones con los clientes. </p>
                        <p>¡No esperes más, solicita una auditoría hoy mismo!</p>
                    </div>
                </div>
                <div className="flex flex-row items-start gap-2">
                    <img className='w-5 mt-[0.3rem]' src={Check} alt="check" />
                    <div className="flex flex-col justify-start [&>p]:mb-2">
                        <h1 className='text-xl font-medium'>Asesoramiento a equipo de marketing in house</h1>
                        <p>¿Estás buscando llevar tu equipo de marketing digital al siguiente nivel? ¡Mi servicio de asesoramiento es la solución perfecta!</p>
                        <p>Te ofrezco asesoramiento personalizado para ayudar a tu equipo a maximizar su potencial y mejorar su rendimiento. Mi experiencia en marketing digital te brindara soluciones prácticas y efectivas para optimizar cualquier problema que pueda estar afectando a tu equipo, te ayudaré a:</p>
                        <ul className='ml-8 mb-2 [&>li]:list-disc'>
                            <li>Plantear objetivos para las campañas digitale</li>
                            <li>Comprender los kpi's importantes</li>
                            <li>Entender el funcionamiento de las plataformas digitales</li>
                            <li>Mejorar la relación de tu equipo con las agencias externas de diseño, paid media y comunity</li>
                            <li>Crear Funnel y flujos de ventas</li>
                        </ul>
                        <p>¡No esperes más para impulsar el éxito de tu equipo de marketing digital, adquiere nuestro servicio de asesoramiento hoy mismo!</p>
                    </div>
                </div>
                <div className="flex flex-row items-start gap-2">
                    <img className='w-5 mt-[0.3rem]' src={Check} alt="check" />
                    <div className="flex flex-col justify-start [&>p]:mb-2">
                        <h1 className='text-xl font-medium'>Speaker: conferencias virtuales y presenciales</h1>
                        <p>Soy Manu Vera, speaker en Marketing Digital con más de 11 años de experiencia en la industria. He trabajado con empresas de todos los tamaños, desde startups hasta grandes corporaciones, para ayudarles a mejorar sus resultados de marketing digital, tuve la fortuna de trabajar en Google Latam.</p>
                        <p>Soy un speaker apasionado y dinámico que sabe cómo conectar con su audiencia. En mis charlas me gusta compartir mis experiencias , mi día a día como director de paid media  y mis conocimiento de las plataformas que utiliza una agencia de marketing. Mis charlas son entretenidas, y siempre dejo a mi audiencia con las herramientas y el conocimiento que necesitan para tener éxito en el mundo digital.</p>
                        <p>Si estás interesado en contratarme como speaker para tu próximo evento, ponte en contacto conmigo hoy mismo. Estaré encantado de responder a cualquier pregunta que tengas y de ayudarte a crear una charla que sea perfecta para tu audiencia.</p>
                    </div>
                </div>                
            </div>
        </div>

    </section>
  )
}
